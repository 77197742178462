@import '../_import.scss';

$left-border: 0.125rem solid var(--theme-true-black);

.bus-stop-lookup {
	display: flex!important;
	flex-flow: column nowrap;
	overflow:hidden;

	.left-side {
		margin: 0 0 0.5in 0;

		.location {
			background: var(--theme-true-black) !important;

			.fav {
				display: none;
			}

			.stop-name, .city {
				color: var(--theme-white) !important;
				margin-left: 0 !important;
			}
		}

		.map {
			border: $left-border;
			border-top: none;
		}

		.boarding-points, .plan-a-trip, .sms {
			border-left: $left-border;
			border-right: $left-border;
		}

		.sms {
			border-bottom: $left-border;
		}
	}

	.left-side, .right-side {
		display: block;
		width: 100%!important;
		flex: 0 0 auto;
	}
}
