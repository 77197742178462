@import '../_import.scss';


.itinerary {
	break-inside: avoid;

	.walk-leg {
		.walk-icon {
			background: var(--theme-true-black) !important;
			color: var(--theme-white) !important;
		}
	}

	.service-leg::before {
		background: var(--theme-light-grey) !important;
	}
}
