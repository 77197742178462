@import '../_import.scss';

html {
	.header-nav {
		background: var(--theme-true-black) !important;
	}

	.logo-link::after {
		content: '';
	}

	.main-nav {
		a {
			color: var(--theme-white) !important;
		}
	}

	.right-nav, .search, .login-button-app {
		display: none !important;
	}
}
