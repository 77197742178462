@import '../_import.scss';

$card-border: 0.0625rem solid var(--theme-true-black);

.card {
	border: $card-border;

	img {
		border-bottom: $card-border;
	}

	.title, .description {
		width: unset;
	}
}
