@import '../_import.scss';

// modified from:
// https://raw.githubusercontent.com/twbs/bootstrap/v4-dev/scss/_print.scss

*,
*::before,
*::after {
	color: var(--theme-true-black) !important; // Black prints faster
	text-shadow: none !important;
	background: transparent !important;
	box-shadow: none !important;
}

body {
	overflow: unset !important;
	min-height: unset !important;
}

a {
	&:not(.button) {
		text-decoration: underline;
	}

	&[href^="tel:"] {
		text-decoration: none;
	}
}

// show links
/*a[href]::after {
	content: " (" attr(href) ")";
}*/

abbr[title]::after {
	content: " (" attr(title) ")";
}

// Don't show links that are fragment identifiers,
// or use the `javascript:` pseudo protocol
a[href^="#"]::after,
a[href^="javascript:"]::after {
	content: "";
}

thead {
	display: table-header-group;
}

tr,
img {
	page-break-inside: avoid;
}

p,
h2,
h3 {
	orphans: 3;
	widows: 3;
}

h2,
h3 {
	page-break-after: avoid;
}

// Specify a size and min-width to make printing closer across browsers.
// We don't set margin here because it breaks `size` in Chrome. We also
// don't use `!important` on `size` as it breaks in Chrome.
@page {
	size: letter
}

$print-min-width: 992px;

body {
	min-width: $print-min-width !important;
}

.container {
	min-width: $print-min-width !important;
}

table {
	border-collapse: collapse !important;

	td,
	th {
		background-color: var(--theme-white) !important;
	}
}
