@import '../_import.scss';

.location-search-bar {
	background-position: 50% !important;
	background-size: cover !important;
	min-height: unset !important;
	padding: 2rem !important;

	> .go, > .instruct, > .instruct > strong {
		color: var(--theme-white) !important;
	}

	> .go {
		margin-top: 0 !important;
	}

	.location-input > input {
		background: var(--theme-white) !important;
		border: 0.0625rem solid var(--theme-light-blue);
		border-top-width: 0.125rem;
	}

	.location-button {
		background: var(--theme-true-black) !important;
		padding: 0;
		width: 3rem;
		text-decoration: none;

		.material-icons {
			color: var(--theme-white) !important;
			margin: 0 0 0 0.375rem;
		}

		.location-label {
			display: none;
		}

		border-radius: 50%;
	}
}

.sidebar {
	.fare, .headlines {
		padding: 0 !important;
		break-inside:avoid;
	}

	.fare{
		margin:0 0 1.5rem 0;
	}
}
