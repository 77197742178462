@import './_responsive.scss';
@import "./_defaults.scss";
@import './_colors.scss';

@mixin focus($color: $theme-true-black, $size: 0.125rem) {
	outline: none;
	box-shadow: 0 0 0 $size $color;
}

@mixin focusDarkBg() {
	@include focus(var(--light-text));
}

@mixin focusDouble($color: $theme-true-black, $color2: $theme-white, $size: 0.125rem) {
	outline: none;
	box-shadow: 0 0 0 $size $color, 0 0 0 ($size * 1.5) $color2;
}

@mixin default-font-family {
	font-family: $default-font-family;
	font-display: optional;
}

@mixin font-body {
	font-family: $default-font-family;
	font-weight: $font-body-weight;
	font-style: normal;
}

@mixin font-body-italic {
	font-family: $default-font-family;
	font-weight: $font-body-weight;
	font-style: italic;
}

@mixin font-bold {
	font-family: $default-font-family;
	font-weight: $font-bold-weight;
	font-style: normal;
}

@mixin font-bold-italic {
	font-family: $default-font-family;
	font-weight: $font-bold-weight;
	font-style: italic;
}

@mixin font-display {
	font-family: $default-font-family;
	font-weight: $font-display-weight;
	font-style: normal;
}

@mixin clear-list-style {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
	clip-path: inset(50%);
	border: 0;
	color: black;
	background: white;
}

@mixin no-sr {
	position: static !important;
	width: 100% !important;
	height: auto !important;
	overflow: visible !important;
	clip: unset !important;
	white-space: unset !important;
	clip-path: unset !important;
}

@mixin sr-only-focusable {
	@include sr-only;

	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
		clip-path: none;
	}
}

@mixin sr-only-unset {
	position: unset;
	width: unset;
	height: unset;
	padding: unset;
	overflow: unset;
	clip: unset;
	white-space: unset;
	clip-path: unset;
	border: unset;
}

@mixin clearfix {
	&::after {
		display: block;
		content: "";
		clear: both;
	}
}

@mixin header-image-text {
	font-size: 4.5rem;
	line-height: 1;
	font-weight: 900;
	font-style: italic;
	text-align: left;
	color: var(--theme-white);
	text-shadow: 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.5);
	text-transform: lowercase;
	letter-spacing: 3.5px;
}

@mixin google-map($min-height, $width: 100%) {
	.google-map {
		min-height: $min-height;
		width: $width;
		display: table;
		margin: 0;
	}

	.map-canvas {
		display: table-cell;
		margin: 0;
		width: 100%;
		height: 100%;
	}
}


@mixin card($color: lighten(var(--theme-light-grey), 3.92%), $shadow-size: 0.3125rem) {
	background: $color;
	border: 0.0.0625rem solid $color;
	box-shadow: $shadow-size $shadow-size 0 rgba(0, 0, 0, 0.15);
}

@mixin remove-ios-button-styles {
	-webkit-appearance: none;
}

@mixin reset-outline-color {
	outline: auto 5px var(--theme-light-blue);
	//outline: auto 5px -webkit-focus-ring-color;
}

@mixin reset-padding() {
	margin-left: -$main-content-padding;
	margin-right: -$main-content-padding;

	@media screen and (max-width: $menu-collapse-menu) {
		margin-left: -$main-content-padding-small;
		margin-right: -$main-content-padding-small;
	}
}

@mixin default-padding {
	padding: $main-content-padding;

	@media screen and (max-width: $menu-collapse-menu) {
		padding: $main-content-padding-small;
	}

	@media only screen and (min-width: $main-content-big-padding) {
		padding: $main-content-padding-big;
	}
}

@mixin default-padding-sides {
	padding-left: $main-content-padding;
	padding-right: $main-content-padding;

	@media screen and (max-width: $menu-collapse-menu) {
		padding-left: $main-content-padding-small;
		padding-right: $main-content-padding-small;
	}

	@media only screen and (min-width: $main-content-big-padding) {
		padding-left: $main-content-padding-big;
		padding-right: $main-content-padding-big;
	}
}
