@import '../_import.scss';


.have-a-question {
	background: var(--theme-true-black) !important;

	p {
		font-size: 1.25rem;
		margin: 0 auto;

		&, a, strong {
			color: var(--theme-white) !important;
		}
	}
}

.footer {
	padding: 0.25in;
	break-inside:avoid;

	a {
		border-bottom: none;
	}

	.logo, .footer-fare, .footer-links {
		display: none;
	}

	.main {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-rows: repeat(3, auto);
		grid-template-areas: "social address" "contact address" "languages address";

		.address {
			grid-area: address;

			a {
				text-decoration: none;
			}
		}

		.social {
			grid-area: social;
			margin: 0;

			a > svg {
				fill: var(--theme-true-black);
			}
		}

		.main-footer-links {
			grid-area: contact;
		}

		.languages {
			grid-area: languages;

			ul {
				margin: 0;
			}

			a {
				border: none;
				padding: 0;
			}
		}
	}
}
