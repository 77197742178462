@import '../_import.scss';

.route-page-header {
	border-bottom: 0.125rem solid var(--theme-true-black);
}

.daytypes, daypart-group, .daypart-name, .daypart {
	border: 0.0625rem solid var(--theme-true-black);
	border-bottom: none;
}

.daypart-name {
	margin-bottom: 0 !important;
}

.daypart.current {
	background: var(--theme-true-black) !important;
	color: var(--theme-white) !important;
}

.material-icons.arrow {
	background: var(--theme-true-black) !important;
	color: var(--theme-white) !important;
}

.schedule {
	overflow: visible !important;
	max-width: none !important;
	width: auto !important;

	&::before{
		content: 'Consider printing this schedule using the printable PDF directly above this message.';
		background:var(--theme-dark-red)!important;
		color:var(--theme-white)!important;
		display:block;
		padding: 0.125in;
		font-size: 1.25rem;
		text-align:center;
	}
}

.page-left, .page-right {
	display: none !important;
}

.schedule-table {
	overflow: visible !important;
	max-width: none !important;
	width: auto !important;

	th {
		background: var(--theme-true-black) !important;
		min-width: unset !important;


		.stop-name {
			padding: 0.5rem 0 !important;
		}

		a, .sms-code-name {
			color: var(--theme-white) !important;
		}

		.sms-code-name {
			background: var(--theme-light-grey) !important;
		}
	}

	td, th {
		border: 0.0625rem solid var(--theme-true-black) !important;
		padding: 0.125rem !important;
	}

	.footnotes-1, .footnotes-2, .footnotes-3, .footnotes-4, .footnotes-5, .footnotes-6, .footnotes-7, .footnotes-8 {
		max-width: unset !important;
		text-align: left;
	}

	.footnotes-1 {
		min-width: 6.25rem !important;
	}

	.footnotes-2 {
		min-width: 6.75rem !important;
	}

	.footnotes-3 {
		min-width: 7.25rem !important;
	}

	.footnotes-4 {
		min-width: 7.75rem !important;
	}

	.footnote-link {
		background: var(--theme-true-black) !important;
		color: var(--theme-white) !important;
	}
}

dl.footnotes {
	display: block !important;
	@include no-sr;

	> div {
		display: flex;
		flex-flow: row nowrap;
		margin: 0 0 0.5rem 0;
	}

	dt {
		flex: 0 0 auto;
		margin-right: 0.25rem;
	}

	dd {
		flex: 1 1 auto;
	}
}
