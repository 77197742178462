@import '../_import.scss';

main > .main-container {
	display: block;

	.secondary-nav {
		display: none !important;
	}
}

.top-nav {
	@include no-sr;
	padding: 1rem 2rem 0 2rem !important;
	margin: 0 0 0.25rem !important;

	> .name {
		font-size: 3rem !important;
		font-weight: $font-body-weight!important;
	}


	.material-icons {
		display: none;
	}
}


.bottom-nav {
	display: none !important;
}
